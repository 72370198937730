import http from '@/utils/http';

// 搜索
export const searchHonor = (params) => {
    return http.request({
        url: '/v1/ballsports/base/honor/search',
        method: 'post',
        data: params
    })
}

// 获取列表
export const getHonor = (params) => {
    return http.request({
        url: '/v1/ballsports/base/honor/list',
        method: 'get',
        params: params
    })
}

// 更新
export const updateHonor = (params) => {
    return http.request({
        url: "/v1/ballsports/base/honor/update",
        method: 'post',
        data: params,
    })
}