<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
            <Form label-position="left" :label-width="80" >
                <FormItem label="id: " v-if="data.id">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.id" disabled></Input>
                        </Col>
                    </Row>
                </FormItem>
                
                <FormItem label="中文: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.title_zh" :class="locks[String(type)+'-1-'+'title_zh'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'title_zh']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="honor_id" :type="type" :sub_type="1" _key="title_zh"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="粤语: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.title_zht" :class="locks[String(type)+'-1-'+'title_zht'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'title_zht']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="honor_id" :type="type" :sub_type="1" _key="title_zht"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="英文: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.title_en" :class="locks[String(type)+'-1-'+'title_en'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'title_en']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="honor_id" :type="type" :sub_type="1" _key="title_en"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="logo: " v-if="data.id">
                    <Row>
                        <imgEditor v-model="data.logo" :prefix="$conf.ballsports.img_prefix" :dir="sport_id===1? 'football/honor/': (sport_id===10 ? '': '')" 
                            :previewButton='true' :clearButton='true' cdn='ballsports' :cdnkey="data.id + '.png' ">
                        </imgEditor>
                    </Row>
                </FormItem>
                
                <FormItem label="是否删除: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.deleted" :class="locks[String(type) + '-1-' + 'deleted'] === 1? 'locked-select': '' ">
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'deleted']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="honor_id" :type="type" :sub_type="1" _key="deleted"></Lock>
                        </Col>
                    </Row>
                </FormItem>
            </Form>
            </TabPane>
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import Lock from '../lock.vue';
import { getHonor, updateHonor} from '@/api/ballsports/base/honor';
import imgEditor from '@/components/img-editor/img-editor.vue';
export default {
    name: "honorEdit",
    props: {
        sport_id: Number,   // 运动id
        honor_id: Number,   // 联赛id
    },
    components: {
        Lock,
        imgEditor,
        // venueTransEdit,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 7,  // 战队
            type: 1,     // 基本数据
            spinShow: false,
            data: {
                id: 0,
                sport_id: self.sport_id,
                name:'',
                deleted: 0,
            },
            locks: {},
        }
    },
    methods: {
        getData () {
            let self = this;
            if (self.honor_id && self.honor_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.honor_id,
                }
                getHonor( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            // self.locks = response.data.data.locks ;
                            self.locks = {}
                        }else{
                            this.data = {}
                            self.locks = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self.locks = {}
                self.data = {
                    id: 0,
                    sport_id: self.sport_id,
                    deleted: 0,
                }
            }
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        save() {
            let self = this;
            if (self.sport_id === 1){
                var params = {
                    id: self.data.id,
                    sport_id: self.data.sport_id || self.sport_id,
                    title_zh: self.data.title_zh,
                    title_zht: self.data.title_zht,
                    title_en: self.data.title_en,
                    logo: self.data.logo,
                    deleted: self.data.deleted,
                }
            }else{
                this.$Message.error("暂不支持的运动项")
                return 
            }
            // console.log(params)
            updateHonor(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
            
        },
        // end
    },
    mounted () {
    },
    watch: {
        "honor_id":{
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    // self.getCategorys();
                    self.getData();
                };
            },
	        immediate: true,  // 深度触发
        }
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>